.content_head{
    display: flex;
    justify-content: space-between;
}
.content_add button{
    background-color: var(--primary);
    color: white !important;
    border-style: none;
    padding: 0 1.5rem !important;
}

.Branch_content .ant-card{
    background-color: var(--secondry_color);
    margin-top: 1rem;
}
.amc_dis_code button{
    border-style: dashed;
    border-color: var(--primary);
    color: var(--primary);
}
.header_filter{
    display: flex;
}
.select_category{
    margin-left: 1rem;
}