.loginSection {
  width: 100%;
  height: 100vh;
  background-image: url("https://t4.ftcdn.net/jpg/02/25/37/39/360_F_225373952_PMgq8u6zsOllDQf6FyeXusU0eALi1RVG.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  filter: blur(8px);
  -webkit-filter: blur(2px);
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.381);
  background-blend-mode: color;
}

.login {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  aspect-ratio: 4/2;
  background-color: white;
  border-radius: 10px;
}

.loginField p {
  color: rgb(179, 176, 176);
}
.loginField h1 {
  color: var(--primary_color);
}

.loginLogo img {
  width: 100%;
  height: 100%;
 aspect-ratio: 3/3;
  border-radius: 10px 0 0 10px;
  object-fit: cover;
}

.loginField {
  margin-top: 2rem;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.loginField button {
  background-color: var(--primary);
  color: white;
  border-style: none;
  padding: 0 3rem;
}

.loginField input {
  width: 100%;
}

.ant-input-affix-wrapper > input.ant-input {
  height: 25px;
}

.user_Icons {
  font-size: 2rem;
  margin-right: 1rem;
  margin-top: .2rem;
  color: var(--primary_color);
}
.form_title {
  display: flex;
  justify-content: center;
  align-content: center;
  height: 10vh;
}

.loginField button{
    width: 100%;
}