/* Testimonial Section Styles */
.testimonial-section {
    padding: 4rem 2rem;
    background-color: #f9f9f9;
}

.testimonial-container {
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    gap: 2rem;
}

.testimonial-card {
    background: #ffffff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 300px;
    padding: 2rem;
    text-align: center;
    transition: transform 0.3s, box-shadow 0.3s;
}

.testimonial-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 16px rgba(0, 0, 0, 0.2);
}

.testimonial-image {
    width: 80px;
    height: 80px;
    border-radius: 50%; /* Circular shape */
    margin: 0 auto 1rem;
    object-fit: cover;
    border: 3px solid var(--primary, #007bff);
}

.testimonial-name {
    font-size: 1.2rem;
    font-weight: bold;
    color: #333;
    margin: 0.5rem 0;
}

.testimonial-role {
    font-size: 0.9rem;
    color: #777;
    margin-bottom: 1rem;
}

.testimonial-text {
    font-size: 1rem;
    color: #555;
    line-height: 1.6;
}

.testimonial-stars {
    color: #ffc107; /* Gold color for stars */
    margin: 1rem 0;
    font-size: 1.2rem;
}

/* Responsive Design */
@media (max-width: 768px) {
    .testimonial-container {
        flex-direction: column;
        align-items: center;
    }
}

/* Image and Video Styling */
.image_hold, .video_hold {
    width: 80px;  /* Ensuring same width for both image and video */
    height: 80px; /* Ensuring same height for both image and video */
    border-radius: 50%; /* Circular shape */
    object-fit: cover;  /* Ensures the content fits the shape */
    margin: 0 auto;
}

/* Center the video container */
.video-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80px; /* Match the height with the image */
    width: 80px; /* Match the width with the image */
}

/* Optional: Optional additional styles for the video */
.video_hold {
    max-width: 100%;
    max-height: 100%;
    border-radius: 50%; /* Optional: keeps the video circular */
}
