.booking_chart {
    margin-top: 2rem;
}

.ant-layout .ant-layout-sider {
    background-color: transparent;
}

.ant-menu-light {
    height: 100vh;
}

.icon_navbar {
    color: var(--primary);
}

.title {
    background-color: white;
}

.main_layout {
    height: 100vh !important;
}

.head_menu {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 1rem;
}

.ant-menu-light.ant-menu-inline .ant-menu-item {
    color: rgb(24, 22, 22);
    font-size: 1rem;
}

.show_user_revanue {
    margin-top: 1rem;
}

.ant-menu-light.ant-menu-inline .ant-menu-item a {
    text-decoration: none;
}

.shoo_recent_order {
    margin-top: 1rem;
}

.shoo_recent_order p {
    font-size: 1.2rem;
    font-weight: 600;
    margin-bottom: 1rem;
}

.ant-menu-light.ant-menu-inline .ant-menu-item .ant-menu-selected {
    /* color: var(--primary_color); */
    background-color: lightgrey;
}

.ant-menu-light .ant-menu-submenu-selected>.ant-menu-submenu-title {
    color: rgb(43, 40, 40);
}

.colorChange {
    background-color: var(--secondry_color);
}

.user_chart_logo img {
    width: 40%;
}

.user_data_item {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.show_user_chat .ant-card {
    height: 16vh;
    background-image: linear-gradient(120deg, #fdfbfb 0%, #ebedee 100%);
}

.show_user_chat {
    margin-top: 1rem;
}

.user_chart_text p:first-child {
    font-size: 1.2rem;
    font-weight: 600;

}

.user_chart_text p:last-child {
    font-size: 1.5rem;
    font-weight: 600;
}

.revanueGraph {
    margin-top: 1rem;
}

.header_menu_icon {
    display: flex;
    align-items: center;
}

.hear_icons {
    margin-right: 1rem;
}

.icon_navbar {
    font-size: 1.5rem;
    margin-right: .5rem;
    cursor: pointer;

}

.icon_navbar:hover {
    color: var(--primary_color);
}

.dashborad_title {
    background-color: ghostwhite;
    padding: 1rem;
}

.count_logo {
    font-size: 2rem;
    margin-right: 1rem;
}

.dashborad_title .ant-card {
    position: relative;

}

.dashborad_title .ant-card-body {
    padding: .8rem;

}

.shw_alalitics {
    margin-top: 1rem;
}
.head_title p{
    font-size: 1.2rem;
    color: green;
}
.shw_alalitics .ant-card{
    margin-top: 1rem;
}
.dashborad_title img {
    width: 30%;
    position: absolute;
    right: 0;
    top: 0;
    filter: blur(5);
}