@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;500&display=swap");

:root {
  --primary: #f8229f;
}

p,
h1,
h2,
h3,
h4 {
  margin-bottom: 0;
}

body {
  margin: 0;
  font-family: "Poppins", sans-serif;
}

.App {
  width: 100vw;
  min-height: 100vh !important;
  overflow-x: hidden;
}

* {
  margin: 0;
  padding: 0;
}


.loader {
  width: 48px;
  height: 48px;
  border: 5px solid var(--primary);
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  position: relative;
  animation: pulse 1s linear infinite;
}

.loader:after {
  content: '';
  position: absolute;
  width: 48px;
  height: 48px;
  border: 5px solid var(--primary);
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  animation: scaleUp 1s linear infinite;
}

@keyframes scaleUp {
  0% {
    transform: translate(-50%, -50%) scale(0)
  }

  60%,
  100% {
    transform: translate(-50%, -50%) scale(1)
  }
}

@keyframes pulse {

  0%,
  60%,
  100% {
    transform: scale(1)
  }

  80% {
    transform: scale(1.2)
  }
}

.loader_main {
  width: 100%;
  display: flex;
  justify-content: center;
}