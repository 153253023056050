.section_title{
    width: 100%;
    display: flex;
    justify-content: space-between;
}
.section_action{
    width: 30%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.section_action .ant-form{
    width: 70%;
}

