.banner_list {
    background-color: var(--secondry_color);
    padding: 1rem;
}



.banner_card .ant-card {
    margin-bottom: 1rem;
    position: relative;
}

/* .banner_card .ant-card-body {
    padding: 0 !important;
} */

.banner_card .ant-card img {
    width: 100%;
    aspect-ratio: 4/3;
    object-fit: cover;
}

.offer_card .ant-card {
    height: 30vh;
    margin-top: 1rem;
}

.offer_card img {
    width: 100%;
    aspect-ratio: 5/5;
    margin-top: 1rem;
    border-radius: 12px;
    -webkit-box-shadow: 3px 4px 4px -2px var(--primary_color);
    box-shadow: 3px 4px 4px -2px var(--primary_color);

}

.banner_action {
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    background-color: red;
    visibility: hidden;
    position: absolute;
    top: 5%;
    left: 5%;
    z-index: 1;
}

.delete_ban_icon{
    color: white;
    font-size: 1.2rem;
}

.banner_visible {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    background-color: var(--primary);
    visibility: visible;
    position: absolute;
    top: 5%;
    left: 5%;
    z-index: 1;
}