.modal_section button{
background-color: var(--primary);
color: white;
border-style: none;
padding: 0 2rem;
}
.des_eye{
    font-size: 1.2rem;
    color: var(--primary);
}
.dynamic-delete-button {
    position: relative;
    background-color: crimson;
    border-radius: 50%;
    top: 4px;
    margin: 0 8px;
    color: white;
    font-size: 24px;
    cursor: pointer;
    transition: all 0.3s;
  }
  .amc_dis_code button{
    border-style: dashed;
  }
  .dynamic-delete-button:hover {
    color: #777;
  }
  .dynamic-delete-button[disabled] {
    cursor: not-allowed;
    opacity: 0.5;
  }

.show_settings{
  width: 100%;
  justify-content: center;
  align-items: center;
}
.show_settings .ant-card{
  width: 50%;
}
#contact_image{
  aspect-ratio: 2/2;
  object-fit: cover;
}
.contactIcon{
  width: 1.5rem;
}
.contact_title{
  display: flex;
  justify-content: space-between;
}
.model_Submit button{
  padding: 0 2.5rem !important;
}