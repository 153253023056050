.del_boy_image .ant-image-img {
    width: 10rem;
    height: 10rem;
    border-radius: 12px;
    object-fit: cover;

}

.del_boy_image p {
    font-size: 1.2vw;
    font-weight: 600;
    color: white;
}

.del_boy_image .ant-image {
    width: 10rem;
    height: 10rem;
    border-radius: 12px;
}

.hear_title button {
    background-color: var(--secondry_color);
}

.back_icon {
    color: var(--primary_color);
    margin-top: -.2rem;
    font-size: 1.2rem;
}

.delivery_title .ant-card {
    background-image: linear-gradient(to top, #a18cd1 0%, #fbc2eb 100%);
}

.delivery_boy_activity {
    text-align: center;

}

.delivery_boy_activity .ant-card {
    width: 100%;
    margin: .5rem;
    background-image: linear-gradient(120deg, #fdfbfb 0%, #ebedee 100%) !important;

}

.delivery_boy_activity .ant-card .ant-card-body {
    padding: .5rem;
    display: flex;
    justify-content: space-between;

}

.del_boy_icons {
    font-size: 2.5rem;
    color: var(--primary_color);

}

.de_boy_act_title p {
    font-size: 1rem;
    font-weight: 600;
}

.del_boy_details {
    margin-top: 1rem;
}

.ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: var(--primary_color) !important;
    font-weight: 600;
}

.del_doc_img {
    border: 1px dashed lightgray;
    border-radius: 12px;
    padding: .2rem;
}

.ant-segmented .ant-segmented-item-selected {
    background-color: #f8229f;
    color: white;
}

.show_selected_data {
    margin-top: 1rem;
}

.vendor_details_title {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.reject_vendor{
    background-color: var(--primary);
    color: white;
    border-style: none;

}
.model_Submit {
    display: flex;
    justify-content: space-evenly;
}

.model_Submit button:last-child {
    background-color: var(--primary);
    color: white;
    border-style: none;
}

.assign_pin button {
    background-color: var(--primary);
    color: white;
    border-style: none;

}

.vendor_Approved {
    background-color: #90D26D;
    border-color: #90D26D;
    color: white;
}

.image_hold {
    width: 100%;
    height: 100%;
    object-fit: cover;
    padding: .5rem;
    border-radius: 100%;
}

.assign_pin {
    text-align: end;
    /* margin-bottom: 1rem; */

}

.wallet {
    display: flex;
    align-items: center;
    margin-bottom: .5rem;
    cursor: pointer;
}

.wallet img {
    width: 2.5rem;
    margin-right: .5rem;
}

.wallet h4 {
    color: var(--primary);
}

#wallet_amt {
    color: var(--primary);
    text-decoration: none;
}

.wallet_title {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

#wallet_amt:hover {

    text-decoration: underline;
}

#withdraw_btn {
    background-color: lightpink;
    color: white;
    border-style: none;
}

#diposit__btn {
    background-color: lightgreen;
    color: white;
    border-style: none;
}



.transIcon {
    font-size: 1.2rem;
    margin-top: -.2rem;
}

.ant-btn-icon {
    margin-inline-end: 0 !important;
}


.admin_profile {
    width: 100%;
    display: flex;
    justify-content: center;
}

.admin_profile .ant-card {
    width: 30%;
    position: relative;
}

.Edit_profile {
    position: absolute;
    bottom: 15%;
    right: 5%;
}

.image_icons {
    width: 100%;
    border-radius: 100%;
    height: 100%;
    padding: .5rem;
}

.model_Subvbmit {
    text-align: end;
}