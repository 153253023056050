.sidebarMenuItem {
  position: sticky;
  left: 0;
  top: 0;
  height: 100vh;
}

.ant-menu-light .ant-menu-item-selected {
  background-color: #f8229f2d;
  color: var(--primary) !important;
  font-weight: 600;
}

.ant-menu-inline .ant-menu-item {
  margin-inline: 0 !important;
}

.ant-menu .ant-menu-item {
  border-radius: 0 25px 25px 0 !important;
}

.sidebar_title img {
  width: 60%;
  margin-right: 0.5rem;
}

.side_log {
  background-color: white;
  text-align: center;
  padding: .5rem 0;
}

.side_sub_menu {
  font-size: 1rem;
}

.side_menu_icon {
  font-size: 1rem !important;
}


/* styles.css */
/* .ripple-button {
  overflow: hidden;
  position: relative;
}

.ripple-button::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 300%;
  height: 300%;
  background-color:var(--primary);
  border-radius: 50%;
  transition: transform 0.6s, opacity 1s;
  transform: translate(-50%, -50%) scale(0);
  opacity: 0;
  pointer-events: none;
}

.ripple-button:hover::after {
  opacity: 1;
  transform: translate(-50%, -50%) scale(1);
}

.ripple-button:active::after {
  opacity: 0;
  transform: translate(-50%, -50%) scale(1.2);
} */