@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;500&display=swap');

body {
  margin: 0;
  font-family: 'Poppins', sans-serif;
}
/* width */
::-webkit-scrollbar {
  width: 8px;

}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #f8229fbd; 
  border-radius: 10px;
  
}
::-webkit-scrollbar-thumb:horizontal{
  visibility: hidden;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #f8229f; 
}

