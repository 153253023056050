.content_titles {
    width: 45%;
    display: flex;
    justify-content: space-between;
}

.pro_selector {
    display: flex;
}

.pro_selector .ant-select {
    margin-right: 1rem;
}

.order_item_list .ant-card .ant-card-body {
    margin: 0 !important;
    padding: 0 !important;
}

#view_invoice {
    border-color: var(--primary);
    color: var(--primary);
}

.vendor_action {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.view_details {
    font-size: 1.5rem;
    color: var(--primary);
    cursor: pointer;
}

.pay_summary {
    margin-top: 1rem;
}
.upload_pincode_models{
    display: flex;
    justify-content: center;
}

.import_file button {
    background-color: green !important;
    border-color: green !important;
    color: white !important;
}

.assign_pin_btn {
    text-align: center;
}

.avtar_icon {
    margin-top: -1rem !important;
}

.pay_item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: .5rem;
}

.pay_item_status {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.assing_ven_title {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.assign_pin_btn button {
    background-color: var(--primary);
    color: white;
    border-style: none;
}

.delete_icon {
    color: red;
    font-size: 1.5rem;
    cursor: pointer;

}

.pagination {
    display: flex;
    justify-content: end;
}

.user_icon {
    font-size: 1.5rem;
    margin-top: -.5rem
}

.show_order_data .ant-card {
    margin-top: 1rem;
}